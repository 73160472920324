var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('the-logo'),_c('h2',{staticClass:"brand-text text-primary ml-1"})],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Login V2"}})],1)]),_c('b-col',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" 个人信息合规管理平台 ")]),_c('b-card-text',{staticClass:"mb-2"}),_c('validation-observer',{ref:"loginValidation"},[_c('b-form',{ref:"loginForm",staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"账号","label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-email","state":errors.length > 0 ? false : null,"name":"login-email","placeholder":"john@example.com"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v("请输入账号")])]}}])})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v("密码")])]),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v("请输入密码")])]}}])})],1),_c('b-form-group',{attrs:{"label":"验证码","label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":"verifyCode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"flex-start"},[_c('b-form-input',{attrs:{"id":"login-verifyCode","state":errors.length > 0 ? false : null,"name":"login-verifyCode","autocomplete":"off"},model:{value:(_vm.verifyCode),callback:function ($$v) {_vm.verifyCode=$$v},expression:"verifyCode"}}),_c('img',{staticClass:"verifycode",attrs:{"src":_vm.imgSrc},on:{"click":_vm.getVerifyCode}})],1),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v("请输入验证码")])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"aggree","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"flex-start"},[_c('b-form-checkbox',{attrs:{"name":"checkbox-1"},model:{value:(_vm.aggree),callback:function ($$v) {_vm.aggree=$$v},expression:"aggree"}},[_vm._v(" 我已阅读并同意 ")]),_c('div',[_c('el-link',{attrs:{"type":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.toAgree.apply(null, arguments)}}},[_vm._v(" 使用条件 ")]),_vm._v(" 及 "),_c('el-link',{attrs:{"type":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.toPrivacyStatement.apply(null, arguments)}}},[_vm._v(" 隐私声明 ")])],1)],1),_c('small',{directives:[{name:"show",rawName:"v-show",value:(!_vm.aggree),expression:"!aggree"}],staticClass:"text-danger"},[_vm._v("请勾选同意条款")])]}}])})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""},on:{"click":_vm.validationForm}},[_vm._v(" 登录 ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }