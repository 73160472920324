<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <the-logo />
        <h2 class="brand-text text-primary ml-1" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        v-loading="loading"
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            个人信息合规管理平台
          </b-card-title>
          <b-card-text class="mb-2" />

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              ref="loginForm"
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="账号"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small
                    v-show="errors[0]"
                    class="text-danger"
                  >请输入账号</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">密码</label>
                  <!--<b-link :to="{ name: 'auth-forgot-password-v2' }">-->
                  <!--<small>忘记密码?</small>-->
                  <!--</b-link>-->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small
                    v-show="errors[0]"
                    class="text-danger"
                  >请输入密码</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="验证码"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="verifyCode"
                  rules="required"
                >
                  <div class="flex-start">
                    <b-form-input
                      id="login-verifyCode"
                      v-model="verifyCode"
                      :state="errors.length > 0 ? false : null"
                      name="login-verifyCode"
                      autocomplete="off"
                    />
                    <img
                      :src="imgSrc"
                      class="verifycode"
                      @click="getVerifyCode"
                    >
                  </div>
                  <small
                    v-show="errors[0]"
                    class="text-danger"
                  >请输入验证码</small>
                </validation-provider>
              </b-form-group>
              <!-- checkbox -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="aggree"
                  rules="required"
                >
                  <div class="flex-start">
                    <b-form-checkbox
                      v-model="aggree"
                      name="checkbox-1"
                    >
                      我已阅读并同意
                    </b-form-checkbox>
                    <div>
                      <el-link
                        type="primary"
                        @click.stop="toAgree"
                      >
                        使用条件
                      </el-link>
                      及
                      <el-link
                        type="primary"
                        @click.stop="toPrivacyStatement"
                      >
                        隐私声明
                      </el-link>
                    </div>
                  </div>

                  <small
                    v-show="!aggree"
                    class="text-danger"
                  >请勾选同意条款</small>
                </validation-provider>

              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                登录
              </b-button>
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
            <span>还没有账号? </span>
            <b-link :to="{name:'page-auth-register-v2'}">
              <span>&nbsp;建立账号</span>
            </b-link>
          </b-card-text> -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import theLogo from '@core/layouts/components/LogoPICaaS.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { error } from '@core/utils/utils'
import { GetVerifyCode } from '@/api/system/user/user'
import Moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    theLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      aggree: true,
      imgSrc: '',
      password: '',
      verifyCode: '',
      verifyCodeId: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    this.getVerifyCode()
    // this.a()
  },
  methods: {
    toPrivacyStatement() {
      const { href } = this.$router.resolve({
        path: '/privacyStatement',
      })
      window.open(href, '_blank')
    },
    toAgree() {
      const { href } = this.$router.resolve({
        path: '/agree',
      })
      window.open(href, '_blank')
    },
    getVerifyCode() {
      GetVerifyCode()
        .then(res => {
          this.imgSrc = res.data.data.verifyCode
          this.verifyCodeId = res.data.data.verifyCodeId
        })
    },
    validationForm() {
      this.$refs.loginValidation.validate()
        .then(success => {
          if (success && this.aggree) {
            this.loading = true
            useJwt.login({
              email: this.userEmail,
              password: this.password,
              verifyCode: this.verifyCode,
              verifyCodeId: this.verifyCodeId,
            })
              .then(response => {
                this.loading = false
                if (response.data.code === 0) {
                  const resData = response.data.data
                  const {
                    token,
                    user,
                  } = response.data.data
                  useJwt.setToken(token)
                  localStorage.setItem('userData', JSON.stringify(user))
                  // 是否初始化
                  if (!resData.isInit && user.isSuperAdmin) {
                    this.$router.replace({ name: 'init' })
                  } else {
                    // 是否过期
                    const { expiresAt } = resData
                    const isExpire = Moment()
                      .isAfter(expiresAt)
                    if (isExpire) {
                      error('您的账号授权已经到期，请联系管理员')
                      // this.$router.replace({ name: 'not-authorized' })
                    } else {
                      this.$router.replace({ name: 'workbench' })
                        .then(() => {
                          this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                              title: `欢迎 ${user.actualName || user.email}`,
                              icon: 'CoffeeIcon',
                              variant: 'success',
                              text: '登录成功',
                            },
                          })
                        })
                    }
                  }
                } else {
                  this.getVerifyCode()
                  error(response.data.msg)
                }
              })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.verifycode {
  display: inline-block;
  width: 200px;
  height: 40px;
  border: 1px solid;
  border-radius: 4px;
  text-align: center;
  line-height: 40px;
  font-size: 24px;
  letter-spacing: 3px;
  margin-left: 10px;
  cursor: pointer;
}
</style>
